





























import Vue from 'vue'

interface ComponentData {
  active: boolean
}

export default Vue.extend({
  data(): ComponentData {
    return {
      active: false
    }
  },

  mounted() {
    this.active = true
  }
})
